<template>
  <div>
    <!-- <this-header /> -->

    <!-- Content -->
    <div class="mt-150 ctx-sde">
      <div class="anan-set-nav-conttainer">

        <b-alert v-if="items.alert_red" variant="danger" show>
          <div class="alert-body" style="white-space: pre-line">
            {{ items.alert_red }}
          </div>
        </b-alert>

        <b-alert variant="primary" show>
          <div
            v-if="items.alert_yellow"
            class="alert-body"
            style="white-space: pre-line"
          >
            {{ items.alert_yellow }}
          </div>
        </b-alert>

        <b-alert v-if="items.alert_green" variant="success" show>
          <div class="alert-body" style="white-space: pre-line">
            {{ items.alert_green }}
          </div>
        </b-alert>

        <div class="flex align-items-center row match-height">
          <!-- <div class="cont-left mr5"> -->
          <div class="col-lg-7 col-md-7 col-sm-12 mb-1 mb-md-0 h-100">
            <slide-banner />
          </div>

          <div class="col-lg-5 col-md-5 col-sm-12 h-100">
            <div
              class="card rounded-lg overflow-hidden fs-13px border-0 bg-gradient-custom-lotview mb-75"
              style="
                box-shadow: 0 2px 20px rgba(0, 0, 0, 0.1);
                height: 240px !important;
              "
            >
              <div
                style="
                  background-image: url('/icon/wave-bg.png') !important;
                  background-position: right bottom;
                  background-repeat: no-repeat;
                  background-size: 100%;
                "
                class="card-img-overlay rounded mb-n1 mx-n1 d-none d-md-block"
              />
              <img src="/icon/car.svg" alt="" class="v-image" height="50" />

              <img src="/icon/ship.svg" alt="" class="v2-image" height="50" />

              <div class="card-body position-relative h-100">
                <h5
                  class="text-white font-weight-bolder mb-50 fs-16px d-flex align-items-center justify-content-between title"
                >
                  <div>{{ $t("key-182") }}</div>
                  <div>
                    <small class="font-weight-bolder"
                      >{{ $t("key-185") }}
                      {{ inthai.lotdate ? time(inthai.lotdate) : "-" }}</small
                    >
                  </div>
                </h5>
                <div v-if="!inthai.checkNothai" style="font-size: 11px">
                  <!-- <p class="text-white head text-center font-weight-bolder">
                    <b-spinner
                      small
                      style="background-color: #262163"
                      type="grow"
                    />
                    {{ $t("key-183") }}

                    <b-spinner small variant="warning" type="grow" />
                    {{ $t("key-184") }}
                  </p> -->
                </div>

                <div
                  v-if="!inthai.checkNothai"
                  style="font-size: 11px"
                  class="mt-1"
                >
                  <div
                    class="row match-height"
                    style="
                      max-height: 160px !important;
                      overflow-y: auto !important;
                    "
                  >
                    <div class="col-6 p-0">
                      <div class="d-flex flex-wrap">
                        <div
                          v-for="(item, index) in itemlotType1"
                          :key="index"
                          class="list-item-car"
                        >
                          <div class="icon-car" />
                          <div class="list-lot">
                            {{ item.name_lot }}
                          </div>
                        </div>
                        <!-- <b-badge
                          v-for="(item, index) in itemlotType1"
                          :key="index"
                          class="cursor-pointer"
                          style="margin-right: 2px; margin-bottom: 3px;background-color: #6B0000;font-size:14px;"
                          variant="danger"
                        >
                          {{ $t('truck') }}/{{ item.name_lot }}
                        </b-badge> -->
                      </div>
                    </div>
                    <div class="col-6 p-0">
                      <div class="d-flex flex-wrap">
                        <div
                          v-for="(item, index) in itemlotType2"
                          :key="index"
                          class="list-item-ship"
                        >
                          <div class="icon-ship" />
                          <div class="list-lot">
                            {{ item.name_lot }}
                          </div>
                        </div>

                        <!-- <b-badge
                          v-for="(item, index) in itemlotType2"
                          :key="index"
                          class="cursor-pointer text-black"
                          style="margin-right: 2px; margin-bottom: 3px;font-size:13px;"
                          variant="warning"
                        >
                          {{ $t('ship') }}/{{ item.name_lot }}
                        </b-badge> -->
                      </div>
                    </div>
                  </div>
                </div>
                <div v-else class="text-white head text-center mt-4">
                  ไม่มีตู้เข้าไทย
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- ON PC -->
        <div class="d-none d-md-block">
          <div class="XhCeW7 mt-2 text-center col-lg-12">
            <div v-for="item in menu" :key="item" class="kmYTt8">
              <b-link :to="{ name: `${item.url}` }">
                <img :src="item.img" alt="" height="45" />
                <div class="mt-50">
                  <span>{{ item.name }}</span>
                </div>
              </b-link>
            </div>
          </div>
        </div>
        <!-- ON MOBILE -->
        <div class="row d-md-none">
          <div v-for="item in menu" :key="item" class="col-4 text-center">
            <b-link :to="{ name: `${item.url}` }">
              <img :src="item.img" alt="" height="45" />
              <div class="_8IM+-o vdmlF5 uUjauV mt-1">
                <span>{{ item.name }}</span>
              </div>
            </b-link>
          </div>
        </div>
      </div>
    </div>

    <div class="anan-set-nav-conttainer mt-1">
      <div class="row mb-1 gap-2">
        <div class="col-md-4">
          <img
            src="/images/banner/banner.webp"
            alt=""
            class="w-100 h-100 rounded"
          />
        </div>

        <div class="col-md-4">
          <div class="card-rate">
            <img src="rate.jpg" alt="" class="w-100 rounded" />
            <div class="rate-buy">{{ GenaralData.rate_preorder }} ฿</div>

            <div class="rate-depo">{{ GenaralData.rate_import }} ฿</div>

            <div class="rate-tran">{{ GenaralData.rate_payment }} ฿</div>
          </div>
        </div>

        <div class="col-md-4">
          <img src="price.jpg" alt="" class="w-100 rounded" />
        </div>
      </div>

      <nav class="stardust-tabs-header-wrapper">
        <ul class="stardust-tabs-header">
          <li
            class="stardust-tabs-header__tab stardust-tabs-header__tab--active"
          >
            <div class="gjTwjy +83iUp" />
            <div class="_8rBYkP">
              <span>Anan shop</span>
            </div>
          </li>
        </ul>
        <i
          class="stardust-tabs-header__tab-indicator"
          style="display: none; width: 100%; transform: translateX(0%)"
        />
      </nav>
      <tmall />

      <nav class="stardust-tabs-header-wrapper mt-1">
        <ul class="stardust-tabs-header">
          <li
            class="stardust-tabs-header__tab stardust-tabs-header__tab--active"
          >
            <div class="gjTwjy +83iUp" />
            <div class="_8rBYkP">
              <span>{{ $t("dailyRecommendations") }}</span>
            </div>
          </li>
        </ul>
        <i
          class="stardust-tabs-header__tab-indicator"
          style="display: none; width: 100%; transform: translateX(0%)"
        />
      </nav>

      <div class="grid-container">
        <!-- <mall-loader
          v-for="n in 7"
          v-if="!items.length"
          :key="n"
        /> -->
        <a
          v-for="item in info"
          :key="item.id"
          class="grid-item"
          :href="item.Url_blank"
          target="_blank"
        >
          <!-- :href="item.detailUrl" target="_blank" -->
          <div>
            <div @click="OpenUrl(item.Url_blank)">
              <div class="H+cO5R">
                <div class="B1W2VS WWmObc">
                  <div style="pointer-events: none">
                    <div class="KbeBa1 tldoKP">
                      <img
                        width="invalid-value"
                        height="invalid-value"
                        class="qtsfFQ a6jjFR"
                        style="object-fit: contain"
                        :src="item.imgUrl"
                      />
                      <div class="YoBZcu">
                        <div class="_7J9NP6 HikggH HAl0O9 C3OUWK">
                          <span class="percent">HOT</span>
                          <span class="+qmUP+">ลด</span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="k3EwZe">
                    <div class="-h7lsO">
                      <div class="glgfCR">
                        <div class="sUq1Dr _1M8qaS">
                          {{ item.title }}
                        </div>
                      </div>
                      <div class="a2AdT4">
                        <div
                          v-if="item.services[0]"
                          class="b0Vn+L mr5"
                          style="color: rgb(215, 38, 3)"
                        >
                          {{ item.services[0] }}
                        </div>

                        <div
                          v-if="item.services[1]"
                          class="b0Vn+L"
                          style="color: rgb(215, 38, 3)"
                        >
                          {{ item.services[1] }}
                        </div>
                      </div>
                    </div>
                    <div class="TPwdp+ Cs-zcr mt-1">
                      <div class="GfQFsW KNL5tj">
                        <span class="EnZ1eY">¥</span
                        ><span class="_3KqMTq">{{ item.currentPrice }}</span>
                      </div>
                      <div class="_6ykn6M aO8bXP">1688.com</div>
                    </div>
                  </div>
                  <div class="shop-item-card__hover-footer UTiGLm">
                    {{ $t("key-88") }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </a>
      </div>
    </div>

    <b-modal
      v-model="isVisible"
      centered
      title="รหัสพัสดุยังค้าง QC"
      size="sm"
      hide-footer
    >
      <div>
        <div class="font-weight-bolder" style="color: #5e5873">
          สวัสดีค่ะ, คุณ {{ userData.username }}
        </div>
        <li class="font-small-3 my-50" style="color: #1673ff">
          มีพัสดุยังค้าง QC จำนวน {{ itemqc }} รายการ กรุณายืนยัน QC
        </li>
        <b-link :to="{ name: 'QC-user' }" class="btn btn-primary btn-sm mt-1">
          <span>ยืนยัน QC</span>
        </b-link>
      </div>
    </b-modal>
    <b-modal
      v-model="isVisibleCoupon"
      centered
      title="เรทคูปองพิเศษ"
      size="ml"
      hide-footer
      header-bg-variant="gradient-primary"
      title-class="custom-title"
      modal-class="custom-modal"
    >
      <b-row class="match-height justify-content-center" v-if="itemCoupon.length">
        <b-col
          v-for="(data, index) in itemCoupon"
          :key="index"
          cols="12"
          class="px-6"
        >
          <b-card
            class="cursor-pointer p-2 "
            style="
              box-shadow: 0px 0px 10px 2px rgba(0, 0, 0, 0.4) !important;
              background: linear-gradient(to left, rgb(0, 128, 128,0.8), rgba(0, 11, 202,0.1))
            "
          >
            <div
              style="
                position: absolute;
                bottom: 10px;
                left: 25px;
                font-size: 10px;
                color: gray;
              "
            >
              <!-- {{ time(data.created_at) }} -->
            </div>
            <div class="d-flex justify-content-between align-items-center">
              <div>
                <!-- <img
                  v-if="data.file_file"
                  :src="GetImg('Notice_file', data.file_file)"
                  :img-alt="data.file_file"
                  width="70px"
                  height="70px"
                /> -->
                <img
                  src="/iconMenu/coupon-rate.svg"
                  width="70px"
                  style="
              box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 1);
            "
                />
              </div>
              <div class="mr-auto ml-1 col-8 text-truncate ">
                <h4 class="text-primary"
                style="
                text-transform: uppercase;
                font-weight: bold;
                font-size: 18px;">
                  {{ data.coupon_name }}
                </h4>

                <div
                  style="font-size: 12px  !important;
                  font-weight: bold;"
                >ราคา {{ Commas(data.price) }} บาท/ตลอดชีพ</div>
              </div>
              <div style="position: absolute; bottom: 10px; right: 15px">
                <b-button
                  size="sm"
                  variant="gradient-primary"
                    style="padding: 5px 12px; font-size: 12px; border-radius: 20px;"
                  @click="Viewitem(data)"
                >
                  อ่านต่อ
                  <feather-icon icon="ChevronsRightIcon" class="p-0"/>
                </b-button>
              </div>
              <div style="position: absolute; top: 30px; right: 30px">
                <img
                  src="/svg/logo_anan.svg"
                  style="cursor: pointer; width: 50px; height: auto;"
                  @click="Viewitem(data)"
               />
              </div>
            </div>
          </b-card>
        </b-col>
      </b-row>
    </b-modal>
    <b-modal
      v-model="isCouponShow"
      centered
      title="รายละเอียด"
      width="200px"
      height="70px"
      hide-footer
      header-bg-variant="gradient-primary"
      title-class="custom-title"
      modal-class="custom-modal"
    >

        <div class="card" v-if="CouponShow" style=" box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.4) !important;">
          <div class="header-cardx p-2">
            <label for="termsAndConditions"><h4 style="color: #196f3d;">{{ $t("termsAndConditions") }}</h4> {{ CouponShow.description }}</label> <br/>
            <label for="Conditions"><h4 style="color: #2980b9;">จำนวนวันที่ใช้ได้ </h4> ตลอดชีพ</label><br/>
            <label for="remarks" v-if="CouponShow.remarks"><h4 style="color: #922b21;">{{ $t("remarks") }} </h4> {{ CouponShow.remarks }}</label><br/>
            <label for="price"><h4 style="color: #f39c12 ;">{{ $t("price") }} </h4> {{ CouponShow.price }} ฿</label><br/>
            <!-- <h3 class="mb-0">
              {{ $t('pricing') }}
            </h3> -->
          </div>
          <div class="p-2">
            <div class="row">
              <div class="col-md-6">
                <table class="table">
                  <thead>
                    <tr>
                      <th scope="col">
                        {{ $t('truck') }}
                      </th>
                      <th scope="col">
                        {{ $t('kilo') }}.
                      </th>
                      <th scope="col">
                        {{ $t('cubic') }}
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="item in car"
                      :key="item.product_type_id"
                    >
                    <!-- .slice().reverse() -->
                      <td>{{ item.product_type_initial }}</td>

                      <td>
                        {{ Commas(item.product_type_kilo) }}
                      </td>
                      <td>
                        {{ Commas(item.product_type_queue) }}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="col-md-6">
                <table class="table">
                  <thead>
                    <tr>
                      <th scope="col">
                        {{ $t('ship') }}
                      </th>
                      <th scope="col">
                        {{ $t('kilo') }}.
                      </th>
                      <th scope="col">
                        {{ $t('cubic') }}
                      </th>
                    </tr>
                  </thead>
                  <tbody >
                    <tr
                      v-for="item in ship"
                      :key="item.product_type_id"
                    >
                    <!-- .slice().reverse() -->
                      <td>{{ item.product_type_initial }}</td>

                      <td>
                          {{ Commas(item.product_type_kilo) }}
                      </td>
                      <td>
                        {{ Commas(item.product_type_queue) }}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <b-button
            size="sm"
            variant="gradient-primary"
            style="padding: 5px 10px; font-size: 14px; margin: 20px 10px 10px 10px; border-radius: 20px;"
            @click="PayCoupon(CouponShow), IsPay=true"
            :disabled="IsPay"
          >
            สั่งซื้อตอนนี้!
          </b-button>
        </div>

    </b-modal>
    <vue-image-lightbox-carousel
      ref="lightbox"
      :show="showLightbox"
      :images="images"
      :show-caption="false"
      @close="closeBox()"
    />
  </div>
</template>

<script>
import {
  BBadge,
  BLink,
  BSpinner,
  BAlert,
  BModal,
  VBModal,
  BCard,
  BCol,
  BButton
} from "bootstrap-vue";
// import MarqueeText from 'vue-marquee-text-component'
import "swiper/css/swiper.css";
// import ThisHeader from '@/views/customer/component/ThisHeader.vue'
import tmall from "@/views/customer/mall.vue";
import moment from "moment-timezone";
import VueImageLightboxCarousel from "vue-image-lightbox-carousel";
import SlideBanner from "./slide-banner.vue";

export default {
  name: "CustomerNewView",
  components: {
    // ThisHeader,
    // BPopover,
    // BImg,
    // Swiper,
    // SwiperSlide,
    BLink,
    BBadge,
    BSpinner,
    tmall,
    BAlert,
    SlideBanner,
    BModal,
    BCard,
    BCol,
    BButton,
    // MarqueeText,
    VueImageLightboxCarousel,
  },
  directives: {
    "b-modal": VBModal,
  },
  data() {
    return {
      /* eslint-disable global-require */
      showMore: false,
      /* eslint-disable global-require */
      swiperData: [{ img: "/banner.jpg" }, { img: "/banner.jpg" }],
      /* eslint-disable global-require */
      items: null,
      swiperOptions: {
        autoplay: {
          delay: 2500,
          disableOnInteraction: false,
        },
        spaceBetween: 30,
        effect: "fade",
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        },
        pagination: {
          el: ".swiper-pagination",
        },
      },
      info: [],
      GenaralData: {
        rate_preorder: 0,
        rate_import: 0,
        rate_payment: 0,
      },
      userData: localStorage.getItem("userData")
        ? JSON.parse(localStorage.getItem("userData"))
        : {},
      itemlot: [],
      itemlotType1: [],
      itemlotType2: [],
      inthai: null,
      isVisible: false,
      itemqc: [],
      showLightbox: false,
      images: [],
      productUrls: {},
      isVisibleCoupon: false,
      itemCoupon: [],
      CouponShow: null,
      isCouponShow: false,
      product_type: [],
      car: [],
      ship: [],
      IsPay: false,
    };
  },
  computed: {
    menu() {
      const newitems = [];
      if (this.userData.ability.some((ele) => ele.subject === "order-list")) {
        newitems.push({
          name: this.$t("orderList"),
          img: require("@/assets/images/anan-img/icon/Delivery-check.png"),
          url: "order-list",
        });
      }
      if (this.userData.ability.some((ele) => ele.subject === "checkbill")) {
        newitems.push({
          name: this.$t("checkStatus"),
          img: require("@/assets/images/anan-img/icon/Check-the-state.png"),
          url: "payment-create",
        });
      }
      if (
        this.userData.ability.some((ele) => ele.subject === "exportproducts")
      ) {
        newitems.push({
          name: this.$t("exportRecords"),
          img: require("@/assets/images/anan-img/icon/without-an-owner.png"),
          url: "export",
        });
      }

      if (this.userData.ability.some((ele) => ele.subject === "tracking")) {
        newitems.push({
          name: this.$t("parcelCode"),
          img: require("@/assets/images/anan-img/icon/Trecking-order.png"),
          url: "tracking",
        });
      }
      if (this.userData.ability.some((ele) => ele.subject === "refill")) {
        newitems.push({
          name: this.$t("topupSystem"),
          img: require("@/assets/images/anan-img/icon/Credit-Card.png"),
          url: "customer-refill",
          role: "refill",
        });
      }
      if (this.userData.ability.some((ele) => ele.subject === "goodsconfirm")) {
        newitems.push({
          name: this.$t("ownerlessGoods"),
          img: require("@/assets/images/anan-img/icon/Trolley.png"),
          url: "goodsconfirm",
          role: "goodsconfirm",
        });
      }
      if (this.userData.ability.some((ele) => ele.subject === "balance")) {
        newitems.push({
          name: this.$t("ledger"),
          img: require("@/assets/images/anan-img/icon/Bank-book.png"),
          url: "balance",
        });
      }
      if (this.userData.ability.some((ele) => ele.subject === "lost_package")) {
        newitems.push({
          name: this.$t("reportMissingGoods"),
          img: require("@/assets/images/anan-img/icon/There-are-no-items.png"),
          url: "lost-package",
        });
      }
      if (this.userData.ability.some((ele) => ele.subject === "point")) {
        newitems.push({
          name: this.$t("points"),
          img: require("@/assets/images/anan-img/icon/Alert.png"),
          url: "point",
        });
      }
      return newitems;
      // [
      // {
      //   name: this.$t("orderList"),
      //   img: require("@/assets/images/anan-img/icon/Delivery-check.png"),
      //   url: "order-list",
      //   role: "order-list",
      // },
      // {
      //   name: this.$t("checkStatus"),
      //   img: require("@/assets/images/anan-img/icon/Check-the-state.png"),
      //   url: "payment-create",
      //   role: "checkbill",
      // },
      // {
      //   name: this.$t("exportRecords"),
      //   img: require("@/assets/images/anan-img/icon/without-an-owner.png"),
      //   url: "export",
      // },
      // {
      //   name: this.$t("parcelCode"),
      //   img: require("@/assets/images/anan-img/icon/Trecking-order.png"),
      //   url: "tracking",
      //   role: "tracking",
      // },
      // {
      //   name: this.$t("topupSystem"),
      //   img: require("@/assets/images/anan-img/icon/Credit-Card.png"),
      //   url: "customer-refill",
      //   role: "refill",
      // },
      // {
      //   name: this.$t("ownerlessGoods"),
      //   img: require("@/assets/images/anan-img/icon/Trolley.png"),
      //   url: "goodsconfirm",
      //   role: "goodsconfirm",
      // },
      // {
      //   name: this.$t("ledger"),
      //   img: require("@/assets/images/anan-img/icon/Bank-book.png"),
      //   url: "balance",
      //   role: "balance",
      // },
      // {
      //   name: this.$t("reportMissingGoods"),
      //   img: require("@/assets/images/anan-img/icon/There-are-no-items.png"),
      //   url: "lost-package",
      //   role: "lost_package",
      // },
      // {
      //   name: this.$t("points"),
      //   img: require("@/assets/images/anan-img/icon/Alert.png"),
      //   url: "point",
      //   role: "point",
      // },
      // { name: 'เติมเงิน', img: require('@/assets/images/anan-img/icon/Top-up.png'), url: '' },
      // ];
    },
  },
  mounted() {
    this.QcPosrnumber();
    this.getPreProduct();
    this.getRate();
    this.getDashboard();
    this.GetLottoDay();
    this.GetCouponRate();
  },
  methods: {
    time(data) {
      return moment(data)
        .tz("Asia/Bangkok")
        .add(543, "year")
        .format("DD/MM/YYYY");
    },
    timelot() {
      return moment().tz("Asia/Bangkok").add(543, "year").format("YY");
    },
    async QcPosrnumber() {
      try {
        const { data: res } = await this.$http.get(
          "/qcpostnumber/QcPostnumberUserPages"
        );
        if (res.data > 0) {
          this.itemqc = res.data;
          // this.$
          this.isVisible = true;

          this.$bvModal.show("modal-qc");
        }
      } catch (err) {
        console.log(err);
      }
    },
    async GetCouponRate() {
      this.isVisibleCoupon = false;
      const { data: res } = await this.$http.get("/coupon/DataRateActive");
      if (res.data.length) {
        this.itemCoupon = res.data;
        this.isVisibleCoupon = true;
        this.isCouponShow = false
      } else {
        this.isVisibleCoupon = false;
      }
    },
    Viewitem(data) {
      if (data) {
        this.CouponShow = data
        this.product_type = data.product_type.sort((a, b) => {
            if (a.product_type_initial < b.product_type_initial) {
                return -1;
            } else if (a.product_type_initial > b.product_type_initial) {
                return 1;
            } else {
                return 0;
            }
         })
        this.car = this.product_type.filter(ele => ele.product_type_class === 1)
        this.ship = this.product_type.filter(ele => ele.product_type_class === 2)
        this.isCouponShow = true
      } else {
        this.product_type = []
        this.ship = []
        this.car = []
        this.CouponShow = null
        this.isCouponShow = false
      }
    },
    PayCoupon(data) {
      this.$swal({
        title: 'ยืนยันที่จะชำระเงิน ?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => { 
        if (result.isConfirmed) {
          const obj = {
            ID: data._id,
            price: data.price,
          }
          this.$http.post('coupon/PayCouponRate', obj).then(response => {
            if (response.data.status) {
                this.IsPay = false
                this.Success('ชำระเงินสำเร็จ')
                this.Viewitem()
            } else {
              this.SwalError(response.data.message)
              this.IsPay = false
            }
          }).catch(error => {
              this.SwalError(error.response.data.message)
              this.IsPay = false
              this.Viewitem()
            })
        
        } else {
          this.IsPay = false
        }
      })
    },
    Success(mes) {
      this.$swal({
        icon: 'success',
        // title: '<h3 style="color: #141414">ทำรายการสำเร็จ</h3>',
        text: mes,
        customClass: {
          confirmButton: 'btn btn-success',
        },
      })
    },
    SwalError(mes) {
      this.$swal({
        icon: 'error',
        title: `<h3 style="color: #141414">${this.$t('key-298')}</h3>`,
        text: mes,
        customClass: {
          confirmButton: 'btn btn-primary',
        },
      })
    },
    async GetLottoDay() {
      try {
        const { data: res } = await this.$http.get("/Lotorder/Tothai");
        // this.itemlot = res
        this.inthai = res.inthai;
        const itemlotType1 = res.data.filter(
          (item) => item.transportType === 1
        );
        const itemlotType2 = res.data.filter(
          (item) => item.transportType === 2
        );
        this.itemlotType1 = itemlotType1;
        this.itemlotType2 = itemlotType2;

        // console.log(res)
      } catch (err) {
        console.log(err);
      }
    },
    async getDashboard() {
      const result = await this.$http.get("/general/show?agent_id=1");
      // console.log(result.data.data)
      this.items = result.data.data;
      this.ShowProduct = false;
    },
    GetImg(pathfile, filename) {
      const matches = filename.match(
        /^data:image\/([A-Za-z-+\/]+);base64,(.+)$/
      );
      if (!matches || matches.length !== 3) {
        return `${this.$http.defaults.baseURL}/viewimg?pathfile=${pathfile}/${filename}`;
      }
      return filename;
    },
    showPicture(picture) {
      this.showLightbox = true;
      const img = { path: this.GetImg("QCPostnumber", picture) };
      this.images.push(img);
    },
    closeBox() {
      this.showLightbox = false;
      this.images = [];
    },
    async getPreProduct() {
      this.$http
        .get("/preproduct/list")
        .then(async (res) => {
          const infoPromises = res.data.data.map(async (ele) => {
            return {
              ...ele,
              Url_blank: await this.ProductByUrl(ele.detailUrl),
            };
          });
          this.info = await Promise.all(infoPromises);
          // console.log(this.info)
        })
        .catch((err) => {
          console.log(err);
        });
    },
    ProductByUrl(url_link) {
      const obj = {
        provider: "",
        id: "",
      };
      const url = new URL(url_link);
      const { host, pathname, search } = url;
      if (host && host.length > 0) {
        const index1688 = host.indexOf("1688");
        const indextaobao = host.indexOf("taobao");
        const indextmall = host.indexOf("tmall");
        if (index1688 > -1) {
          obj.provider = "1688";
          if (host === "mindx.1688.com") {
            obj.id = url.searchParams.get("offerId");
          } else {
            const IndexCheck = pathname.indexOf("/offer/");
            if (IndexCheck > -1) {
              const of = new RegExp("/offer/", "g");
              const ht = new RegExp(".html", "g");
              obj.id = pathname.replace(of, "");
              obj.id = obj.id.replace(ht, "");
            }
          }
        } else if (indextaobao > -1) {
          obj.provider = "taobao";
          const IndexCheck = search.indexOf("itemIds");
          if (IndexCheck > -1) {
            obj.id = url.searchParams.get("itemIds");
          } else {
            obj.id = url.searchParams.get("id");
          }
        } else if (indextmall > -1) {
          obj.provider = "tmall";
          obj.id = url.searchParams.get("id");
        }
        if (obj.provider && obj.id) {
          return `https://portal.anan-cargo.com/product2/${obj.provider}/${obj.id}`;
        } else {
          return null;
        }
      }
    },
    async getRate() {
      try {
        const params = {
          agent_id: this.userData.agent_id,
        };
        const { data: res } = await this.$http.get("/general/show", { params });
        if (res.status) {
          this.GenaralData = res.data;
        }
      } catch (error) {
        console.log(error);
      }
    },
    async OpenUrl(url_link) {
      if (url_link) {
        window.open(url_link, "_blank");
      }
    },
    // eslint-disable-next-line camelcase
    async GetProductByUrl(url_link) {
      console.log(url_link);
      const obj = {
        provider: "",
        id: "",
      };
      const url = new URL(url_link);
      const { host, pathname, search } = url;
      if (host && host.length > 0) {
        const index1688 = host.indexOf("1688");
        const indextaobao = host.indexOf("taobao");
        const indextmall = host.indexOf("tmall");
        if (index1688 > -1) {
          obj.provider = "1688";
          if (host === "mindx.1688.com") {
            obj.id = url.searchParams.get("offerId");
          } else {
            const IndexCheck = pathname.indexOf("/offer/");
            if (IndexCheck > -1) {
              const of = new RegExp("/offer/", "g");
              const ht = new RegExp(".html", "g");
              obj.id = pathname.replace(of, "");
              obj.id = obj.id.replace(ht, "");
            }
          }
        } else if (indextaobao > -1) {
          obj.provider = "taobao";
          const IndexCheck = search.indexOf("itemIds");
          if (IndexCheck > -1) {
            obj.id = url.searchParams.get("itemIds");
          } else {
            obj.id = url.searchParams.get("id");
          }
        } else if (indextmall > -1) {
          obj.provider = "tmall";
          obj.id = url.searchParams.get("id");
        }
        if (obj.provider && obj.id) {
          const newUrl = `https://portal.anan-cargo.com/product2/${obj.provider}/${obj.id}`;
          // console.log(newUrl)
          // this.$router.push({
          //   name: `product2-${obj.provider}`,
          //   params: { id: obj.id },
          // })
          // return obj;
          window.open(newUrl, "_blank");
        } else {
          this.Alert(
            false,
            "ไม่พบข้อมูลสินค้า",
            "กรุณากรอก URL ใหม่ให้ถูกต้อง"
          );
        }
      }
    },
  },
};

</script>

<style lang="scss" scoped>
.mb-view {
  margin-top: 8.5vh;
}

.olcards,
.olcards * {
  margin: 0;
  padding: 0;
}

.olcards {
  list-style: none;
  counter-reset: cardCount;
  display: flex;
  flex-direction: column;
  justify-content: center;
  --cardsGap: 1rem;
  gap: var(--cardsGap);
  padding-bottom: var(--cardsGap);
}

.olcards li {
  counter-increment: cardCount;
  display: flex;
  color: white;
  --labelOffset: 1rem;
  --arrowClipSize: 1.5rem;
}

.olcards li::before {
  content: counter(cardCount, decimal-leading-zero);
  background: white;
  color: var(--cardColor);
  font-size: 2em;
  font-weight: 700;
  transform: translateY(calc(-1 * var(--labelOffset)));
  margin-right: calc(-1 * var(--labelOffset));
  z-index: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-inline: 0.5em;
}

.olcards li .content {
  background-color: var(--cardColor);
  --inlinePadding: 1em;
  --boxPadding: 0.5em;
  padding: var(--boxPadding) calc(var(--inlinePadding) + var(--arrowClipSize))
    var(--boxPadding) calc(var(--inlinePadding) + var(--labelOffset));
  grid-template-areas:
    "icon title"
    "icon text";
  gap: 0.25em 1em;
  clip-path: polygon(
    0 0,
    calc(100% - var(--arrowClipSize)) 0,
    100% 50%,
    calc(100% - var(--arrowClipSize)) 100%,
    calc(100% - var(--arrowClipSize)) calc(100% + var(--cardsGap)),
    0 calc(100% + var(--cardsGap))
  );
  position: relative;
  display: flex;
  align-items: center;
  width: 85%;
  height: 70px;
  text-align: left !important;
}

.olcards li .content::before {
  content: "";
  position: absolute;
  width: var(--labelOffset);
  height: var(--labelOffset);
  background: var(--cardColor);
  left: 0;
  bottom: 0;
  clip-path: polygon(0 0, 100% 0, 0 100%);
  filter: brightness(0.75);
}

.olcards li .content::after {
  content: "";
  position: absolute;
  height: var(--cardsGap);
  width: var(--cardsGap);
  left: 0;
  top: 100%;
}

.olcards li .icon {
  grid-area: icon;
  align-self: center;
  font-size: 2em;
}

.olcards li .content .title {
  grid-area: title;
  font-size: 1.1em;
  font-weight: 700;
}

.olcards li .content .text {
  max-width: 100%;
  max-height: 70px;
  grid-area: text;
  font-size: 0.5em;
  //  white-space: nowrap;
  overflow-y: auto;
  text-overflow: ellipsis;
}

.text::-webkit-scrollbar {
  display: none;
}

.olcards li:not(:nth-last-child(-n + 3)) {
  display: none;
}

.ml-p1 {
  margin-right: 5px;
  margin-bottom: 5px;
}

.title {
  position: relative;
  top: 0;
}

.head {
  height: 5px;
}

@media only screen and (max-width: 600px) {
  .gap-2 {
    gap: 10px !important;
  }

  .H\+cO5R .B1W2VS:hover {
    z-index: 0 !important;
  }

  .WWmObc {
    z-index: -1 !important;
  }
}

.notice {
  width: 100% !important;
  height: 100% !important;
  background-color: transparent;
  border: 0;
  color: white;
  font-size: 12px;
  padding: 5px 0;
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 999;
}

.popup {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  padding: 20px;
  z-index: 1000;
}
.card-notice:hover {
  transform: scale(1.05);
  box-shadow: 0 10px 20px rgba(0, 0, 0, .12), 0 4px 8px rgba(0, 0, 0, .06);
}
</style>
