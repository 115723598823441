<template>
  <div>
    <div class="grid-item">
      <div class="card">
        <skeleton-loader-vue
          type="rect"
          :width="231"
          :height="229"
          animation="wave"
        />

        <div class="p-1">
          <skeleton-loader-vue
            type="rect"
            :width="150"
            :height="15"
            animation="wave"
            :rounded="true"
          />

          <skeleton-loader-vue
            type="rect"
            :width="80"
            :height="15"
            style="margin-top: 5px;"
            animation="wave"
            :rounded="true"
          />

          <div class="d-flex align-items-center justify-content-between">
            <skeleton-loader-vue
              type="rect"
              :width="50"
              :height="15"
              style="margin-top: 5px;"
              animation="wave"
              :rounded="true"
            />

            <skeleton-loader-vue
              type="rect"
              :width="70"
              :height="15"
              style="margin-top: 5px;"
              animation="wave"
              :rounded="true"
            />
          </div>

        </div>
      </div>
    </div>
  </div>
</template>

<script>
// eslint-disable-next-line import/no-extraneous-dependencies
import SkeletonLoaderVue from 'skeleton-loader-vue'

export default {
  name: 'Mall',
  components: {
    SkeletonLoaderVue,
  },
}
</script>

  <style lang="scss" scoped></style>
