<!-- eslint-disable vue/no-use-v-if-with-v-for -->
<template>
  <div>

    <!-- <this-header /> -->

    <!-- <nav
      class="stardust-tabs-header-wrapper"
    >
      <ul class="stardust-tabs-header">
        <li class="stardust-tabs-header__tab stardust-tabs-header__tab--active">
          <div class="gjTwjy +83iUp" /><div class="_8rBYkP">
            <span>สินค้าแนะนำประจำวัน</span>
          </div>
        </li>
      </ul>
      <i
        class="stardust-tabs-header__tab-indicator"
        style="display: none; width: 100%; transform: translateX(0%);"
      />
    </nav> -->

    <div class="grid-container">
      <div
        v-for="n in 4"
        :key="n"
      >
        <!-- <mall-loader
          v-if="!items.length"
        /> -->
      </div>
      <div
        v-for="item in items"
        :key="item._id"
        class="grid-item"
      >
        <div>

          <router-link
            :to="{ name: 'product', params: { id: item._id }}"
            class="shop-item-card"
            style="width: 100%; height: 100%;"
          >
            <div class="H+cO5R">
              <div class="B1W2VS WWmObc">
                <div style="pointer-events: none">
                  <div class="KbeBa1 tldoKP">
                    <img
                      width="invalid-value"
                      height="invalid-value"
                      :alt="item.name"
                      class="qtsfFQ a6jjFR"
                      style="object-fit: contain"
                      :src="item.picture[0]"
                    >
                    <div class="YoBZcu">
                      <div class="_7J9NP6 HikggH HAl0O9 C3OUWK">
                        <span class="percent">{{ item.discount }}%</span>
                        <span class="+qmUP+">ลด</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="k3EwZe">
                  <div class="-h7lsO">
                    <div class="glgfCR">
                      <div class="sUq1Dr _1M8qaS">
                        {{ item.name }}
                      </div>
                    </div>
                    <div class="a2AdT4">
                      <div
                        class="b0Vn+L mr5"
                        style="color: rgb(215, 38, 3)"
                      >
                        {{ $t('key-259') }}
                      </div>
                    </div>
                  </div>
                  <div class="TPwdp+ Cs-zcr mt-1">
                    <div class="GfQFsW KNL5tj">
                      <span class="EnZ1eY">฿</span><span class="_3KqMTq">{{ item.price }}</span>
                    </div>
                    <div class="_6ykn6M aO8bXP">
                      ขายแล้ว {{ formatValue(item.price) }}พัน ชิ้น
                    </div>
                  </div>
                </div>
                <div class="shop-item-card__hover-footer UTiGLm">
                  {{ $t('key-88') }}
                </div>
              </div>
            </div>
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// eslint-disable-next-line import/no-extraneous-dependencies
// import SkeletonLoaderVue from 'skeleton-loader-vue'
import mallLoader from '@/views/customer/loader/mallLoader.vue'
// import ThisHeader from './product/component/ThisHeader.vue'

export default {
  name: 'Mall',
  components: {
    // SkeletonLoaderVue,
    mallLoader,
    // ThisHeader,
  },
  data() {
    return {
      url_link: '',
      items: [],
    }
  },
  mounted() {
    this.GetProduct()
  },
  methods: {
    async GetProductByUrl() {
      const obj = {
        provider: '',
        id: '',
      }
      const url = new URL(this.url_link)
      const { host, pathname, search } = url
      if (host && host.length > 0) {
        const index1688 = host.indexOf('1688')
        const indextaobao = host.indexOf('taobao')
        const indextmall = host.indexOf('tmall')
        if (index1688 > -1) {
          obj.provider = '1688'
          if (host === 'mindx.1688.com') {
            obj.id = url.searchParams.get('offerId')
          } else {
            const IndexCheck = pathname.indexOf('/offer/')
            if (IndexCheck > -1) {
              const of = new RegExp('/offer/', 'g')
              const ht = new RegExp('.html', 'g')
              obj.id = pathname.replace(of, '')
              obj.id = obj.id.replace(ht, '')
            }
          }
        } else if (indextaobao > -1) {
          obj.provider = 'taobao'
          const IndexCheck = search.indexOf('itemIds')
          if (IndexCheck > -1) {
            obj.id = url.searchParams.get('itemIds')
          } else {
            obj.id = url.searchParams.get('id')
          }
        } else if (indextmall > -1) {
          obj.provider = 'tmall'
          obj.id = url.searchParams.get('id')
        }
        if (obj.provider && obj.id) {
          this.$router.push({ name: `product-${obj.provider}`, params: { id: obj.id } })
        } else {
          this.Alert(false, 'ไม่พบข้อมูลสินค้า', 'กรุณากรอก URL ใหม่ให้ถูกต้อง')
        }
      }
    },
    formatValue(value) {
      if (value < 1000) return value
      return (value / 1000).toFixed(1)
    },
    GetProduct() {
      this.$http
        .get('/product/list')
        .then(response => {
          // console.log(response.data)
          this.items = response.data
        })
        .catch(error => console.log(error))
    },
    Alert(status, title, mes) {
      this.$swal({
        icon: `${status ? 'success' : 'error'}`,
        title: `<h3 style="color: #141414">${title}</h3>`,
        text: mes,
        customClass: {
          confirmButton: 'btn btn-success',
        },
      })
    },
  },
}
</script>

<style lang="scss" scoped></style>
